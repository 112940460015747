import classes from '../../style/Team.module.css';

const FacebookButton = (props) => {
    return (
        <a className={classes.social_link} href={`https://facebook.com/${props.name}`} target="_blank" >
            <img src={require('../../images/ui/facebook-logo.png')} alt='facebook logo'/>
        </a>
    )
}

export default FacebookButton;