import classes from '../../style/Main.module.css';
import { useNavigate } from 'react-router-dom';

const LandingArea = () => {

    const backgroundImage = require('../../images/ui/background-dramatic.jpeg');
    const navigate = useNavigate();

    return (
        <div className={classes.main_first} style={{'backgroundImage':`url(${backgroundImage})`}}> 
            <img src={require('../../images/ui/b2b-logo-white.png')} alt='b2b logo'/>
            <div className={classes.button} onClick={()=>navigate('/contact')}>
                <span>Free Assessment</span>
            </div>
        </div>
    )
}

export default LandingArea;