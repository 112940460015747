import classes from '../../style/Team.module.css';

const CalendarButton = (props) => {
    return (
        <a className={classes.social_link} href={props.url} target="_blank" >
            <img src={require('../../images/ui/calendar.png')} alt='calendar logo'/>
        </a>
    )
}

export default CalendarButton;