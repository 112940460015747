import TeamMember from './TeamMember';
import classes from '../../style/Main.module.css';
import { useNavigate } from 'react-router-dom';
import PeopleData from '../../data/PeopleData.json';

const TeamArea = () => {

    const navigate = useNavigate();

    // randomize people
    PeopleData.map((d,i)=>d.id=Math.random())
    PeopleData[0].id = 100; // ensure anthony is first
    PeopleData.sort((a,b)=>b.id-a.id);
    
    return (
        <div className={classes.main_people}>
            <div className={classes.main_people_title} onClick={()=>navigate('/team')}>The Team</div>
            <div className={classes.main_people_slider}>
                <img className={classes.main_people_touch_icon} src={require(`../../images/ui/touch-icon.png`)}/>
                <img className={classes.main_people_slide_icon} src={require(`../../images/ui/slide-icon.png`)}/>
                {
                    PeopleData.map((d,i)=><TeamMember key={`home_team_key_${i}`} firstName={d.first} lastName={d.last} img={d.img} bio={d.bio}/>)
                }
            </div>
        </div>
    )
}

export default TeamArea;