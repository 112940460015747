import classes from '../../style/Team.module.css';

const TikTokButton = (props) => {
    return (
        <a className={classes.social_link} href={`https://tiktok.com/@${props.name}`} target="_blank" >
            <img src={require('../../images/ui/tiktok-logo.png')} alt='tiktok logo'/>
        </a>
    )
}

export default TikTokButton;