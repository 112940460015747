import classes from '../style/Contact.module.css';
import ContactForm from '../components/ContactForm';
import { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
    
    window.scrollTo(0, 0);
    const [message,setMessage] = useState("");
    const [loading,setLoading] = useState(false);

    useEffect(() => emailjs.init("73ivtCk7w8CRVJgPA"), []);
    const form = useRef();

    const handleSubmit = (event) => {
        event.preventDefault();
        if(loading) return;
        setLoading(true);
        emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE, process.env.REACT_APP_EMAILJS_TEMPLATE, form.current)
            .then((result) => {
                // show the user a success message
                console.log('result: ',result)
                form.current.reset();
                setMessage("Success! We'll be in touch!");
                setLoading(false);
            }, (error) => {
                // show the user an error
                console.log('error: ',error)
                setMessage('Uh oh, there was a problem. Please try again.')
                setLoading(false);
            });
    };

    return (
        <div className={classes.contact_layout}>
            <div className={classes.contact_title}>Send us a message</div>
            { (message && <p>{message}</p>) }
            <ContactForm form={form} handleSubmit={handleSubmit} loading={loading}/>
        </div>
    )
}

export default Contact;