import Member from '../components/Member';
import teamClasses from '../style/Team.module.css';
import PeopleData from '../data/PeopleData.json';

const Team = () => {
    
    window.scrollTo(0, 0);
    const backgroundImage = require('../images/ui/dumbbells-blurred-optimized.jpeg');

    // randomize people
    PeopleData.map((d,i)=>d.id=Math.random())
    PeopleData[0].id = 100; // ensure anthony is first
    PeopleData.sort((a,b)=>b.id-a.id);

    return (
        <div className={teamClasses.team_layout} style={{'backgroundImage':`url(${backgroundImage})`}}>
            <div className={teamClasses.team_members}>
                <div className={teamClasses.team_title}>
                    The Team
                </div>
                {
                    PeopleData.map((d,i)=> (
                        <Member 
                            key={`team_key_${i}`}
                            firstName={d.first} 
                            lastName={d.last} 
                            img={d.img} 
                            bio={d.bio}
                            x={d.x}
                            instagram={d.instagram}
                            tiktok={d.tiktok}
                            facebook={d.facebook}
                            website={d.website}
                            calendar={d.calendar}
                        />
                    ))
                }
            </div>
        </div>
    )
}

export default Team;