import { useNavigate } from 'react-router-dom';
import classes from '../../style/Main.module.css';

const ShopArea = () => {

    const navigate = useNavigate();

    return (
        <div className={classes.main_shop_area}>
                <div className={classes.main_shop_title}>
                    Gear
                </div>
                <div className={classes.main_shop_item_area}>

                    <a className={classes.main_shop_item} href='https://www.rokkitwear.com/school/56250-back-to-basics-movement-and-training/product/638-russell-dri-power-fleece-hoodie/1746/53/1/602626/0/36' target="_blank" rel="noreferrer" >
                        <img className={classes.main_shop_item_image} src={require('../../images/products/hoody_1.png')}/>
                        <div className={classes.main_shop_item_info}>
                            <div className={classes.main_shop_item_name}>
                                Hoody
                            </div>
                            <div className={classes.main_shop_item_price}>
                                $53.99
                            </div>
                        </div>
                    </a>

                    <a className={classes.main_shop_item} href='https://www.rokkitwear.com/school/56250-back-to-basics-movement-and-training/product/1230-momentum-tee/2256/53/3/602879/0/122' target="_blank" rel="noreferrer" >
                        <img className={classes.main_shop_item_image} src={require('../../images/products/tshirt_1.png')}/>
                        <div className={classes.main_shop_item_info}>
                            <div className={classes.main_shop_item_name}>
                                T-Shirt
                            </div>
                            <div className={classes.main_shop_item_price}>
                                $28.99
                            </div>
                        </div>
                    </a>

                    <a className={classes.main_shop_item} href='https://www.rokkitwear.com/school/56250-back-to-basics-movement-and-training/product/1245-next-level-tri-blend-tank/2566/96/3/602879/0/90' target="_blank" rel="noreferrer" >
                        <img className={classes.main_shop_item_image} src={require('../../images/products/tanktop_1.png')}/>
                        <div className={classes.main_shop_item_info}>
                            <div className={classes.main_shop_item_name}>
                                Tank Top
                            </div>
                            <div className={classes.main_shop_item_price}>
                                $27.99
                            </div>
                        </div>
                    </a>
                    
                </div>
        </div>
    )
}

export default ShopArea;