import classes from '../style/Gallery.module.css';

const Gallery = () => {
    
    window.scrollTo(0, 0);

    return (
        <>
            <div className={classes.gallery_title}>
                Gallery
            </div>
            <div className={classes.layout}>
                <img className={classes.horizontal} src={require('../images/photos/Back2Basics-8824-h.jpg')} alt='frame and benches'/>
                <img className={classes.vertical} src={require('../images/photos/Back2Basics-8831-v.jpg')} alt='weight sled'/>
                <img className={classes.vertical} src={require('../images/photos/Back2Basics-8829-v.jpg')} alt='weights'/>
                <img className={classes.horizontal} src={require('../images/photos/Back2Basics-8826-h.jpg')} alt='dumbbell rack'/>

                <img className={classes.horizontal} src={require('../images/photos/Back2Basics-8828-h.jpg')} alt='treadmills'/>
                <img className={classes.vertical} src={require('../images/photos/Back2Basics-8836-v.jpg')} alt='fram vertical'/>
                <img className={classes.vertical} src={require('../images/photos/Back2Basics-8835-v.jpg')} alt='bars'/>
                <img className={classes.horizontal} src={require('../images/photos/Back2Basics-8825-h.jpg')} alt='frame'/>

                <img className={classes.horizontal} src={require('../images/photos/Back2Basics-8843-h.jpg')} alt='room horizontal'/>
                <img className={classes.vertical} src={require('../images/photos/Back2Basics-8842-v.jpg')} alt='room vertical'/>
            </div>
        </>
    );
}

export default Gallery;