import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RootLayout from './layout/RootLayout';
import Home from './pages/Home';
import Team from './pages/Team';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import './App.css';

const router = createBrowserRouter([
  { 
    path: '/', 
    element: <RootLayout/>,
    // errorElement: <ErrorPage/>,
    children: [
      { index: true, element: <Home/> },
      { path: 'team', element: <Team/>},
      { path: 'gallery', element: <Gallery/>},
      { path: 'contact', element: <Contact/>}
    ]
  }
])

const App = () => {
  return <RouterProvider router={router}/>;
}

export default App;
