import classes from '../../style/Team.module.css';

const XButton = (props) => {
    return (
        <a className={classes.social_link} href={`https://x.com/${props.name}`} target="_blank" >
            <img src={require('../../images/ui/x-logo.png')} alt='x logo'/>
        </a>
    )
}

export default XButton;