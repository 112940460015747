import classes from '../../style/Team.module.css';

const InstagramButton = (props) => {
    return (
        <a className={classes.social_link} href={`https://instagram.com/${props.name}`} target="_blank" >
            <img src={require('../../images/ui/instagram.png')} alt='instagram logo'/>
        </a>
    )
}

export default InstagramButton;