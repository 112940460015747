import { useNavigate } from 'react-router-dom';
import classes from '../../style/Main.module.css';

const GalleryArea = () => {

    const backgroundImage = require('../../images/photos/Back2Basics-8828-h-thin.jpg');
    const navigate = useNavigate();

    return (
        <div className={classes.main_gallery_section} style={{'backgroundImage':`url(${backgroundImage})`}} onClick={()=>navigate('/gallery')}> 
            <div className={classes.main_gallery_section_button}>See the gym</div>
        </div>
    )
}

export default GalleryArea;