import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

let RootLayout = () => {
    return (
        <div style={{'height': '100%'}}>
            <Header/>
            <Outlet/>
            <Footer/>
        </div>
    );
}

export default RootLayout;