import classes from '../style/Main.module.css';
import TeamArea from '../components/home/TeamArea';
import LandingArea from '../components/home/LandingArea';
import GalleryArea from '../components/home/GalleryArea';
import AboutArea from '../components/home/AboutArea';
import ContactArea from '../components/home/ContactArea';
import ShopArea from '../components/home/ShopArea';

const Home = () => {

    //window.scrollTo(0, 0);
    
    return (
        <div className={classes.main_new_layout}>
            <LandingArea/>
            <AboutArea/>
            <TeamArea/>
            <GalleryArea/>
            <ShopArea/>
            <ContactArea/>
        </div>
    )
}

export default Home;