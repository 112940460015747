import classes from '../../style/Team.module.css';

const WebsiteButton = (props) => {
    return (
        <a className={classes.social_link} href={`${props.url}`} target="_blank" >
            <img src={require('../../images/ui/website.png')} alt='website logo'/>
        </a>
    )
}

export default WebsiteButton;