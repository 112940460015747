import classes from '../../style/Main.module.css';

const AboutArea = () => {
    return (
        <div className={classes.main_about}>
            <div className={classes.main_about_pic_tall} style={{backgroundImage:`url(${require('../../images/photos/Back2Basics-8831-v.jpg')})`}}/>
            <div className={classes.main_about_rightside}>
                <div className={classes.main_about_text}>
                    <div className={classes.main_about_title}>Your Goals</div>
                    <p>Your fitness journey is as unique as you are.</p>
                    <p>We're not about one-size-fits-all workouts. Instead, we tailor each session to your personal goals and preferences. Join us in a welcoming and friendly space where achieving your fitness milestones feels like a team effort.</p>
                </div>
                <div className={classes.main_about_pic_rightside} style={{backgroundImage:`url(${require('../../images/photos/Back2Basics-8824-h-square.jpg')})`}}/>
            </div>
        </div>
    )
}

export default AboutArea;