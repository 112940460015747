import { useNavigate } from 'react-router-dom';
import classes from '../../style/Main.module.css';

const ContactArea = () => {

    const navigate = useNavigate();

    return (
        <div className={classes.main_contact_area}>
            <div className={classes.main_contact_area_inner}>
                <div className={classes.main_contact_text}>
                    Get your free no-obligation assessment with one of our trainers today
                </div>
                <div className={classes.main_contact_button} onClick={()=>navigate('/contact')}>
                    <span>Message Us</span>
                </div>
            </div>
        </div>
    )
}

export default ContactArea;