import { useNavigate } from 'react-router-dom';
import classes from '../../style/Main.module.css';

const TeamMember = (props) => {
    const navigate = useNavigate();
    return (
        <div className={classes.main_people_person} onClick={()=>navigate('/team')}>
            <img className={classes.main_person_photo} src={require(`../../images/people/${props.img}`)} alt="Team member"/>
            <div className={classes.main_person_name_first}>{props.firstName}</div>
            <div className={classes.main_person_name_last}>{props.lastName}</div>
        </div>
    )
}

export default TeamMember;