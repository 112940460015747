import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import logo from '../images/ui/b2b-logo-alt-white.png';
import classes from '../style/Header.module.css';
import { useEffect, useState } from 'react';

const Header = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [showHomepageHeader,setShowHomepageHeader] = useState(false);

    useEffect(()=>{
        const handleScroll = () => {
            if(location.pathname !== '/' || window.innerWidth > 450){
                setShowHomepageHeader(true);
                return;
            }
    
            if(window.scrollY<460) setShowHomepageHeader(false);
            else setShowHomepageHeader(true);
        };
        handleScroll();
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll); // cleauo
    },[location.pathname])

    return (
        <>
            <header className={`
                ${location.pathname==='/'?classes.homepage_only:classes.non_homepage_only}
                ${showHomepageHeader?'':classes.homepage_only_hide}  
            `}>
                <Link to=''>
                    <img src={logo} alt='b2b logo'/>
                </Link>
                { location.pathname!=='/' && (
                    <div className={classes.menu_area}>
                        <NavLink to='' className={({isActive,isPending})=>isPending ? "pending" : isActive ? classes.active : ""}>Home</NavLink>
                        <NavLink to='team' className={({isActive,isPending})=>isPending ? "pending" : isActive ? classes.active : ""}>Team</NavLink>
                        <NavLink to='gallery' className={({isActive,isPending})=>isPending ? "pending" : isActive ? classes.active : ""}>Gallery</NavLink>
                        <NavLink to='contact' className={({isActive,isPending})=>isPending ? "pending" : isActive ? classes.active : ""}>Contact</NavLink>
                    </div>
                )}
                <div className={`${classes.header_button} ${(location.pathname==='/contact'?classes.header_button_hide:'')}`} onClick={()=>navigate('/contact')}>
                    <span>Free Assessment</span>
                </div>
            </header>
        </>
    )
}

export default Header;