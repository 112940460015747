import classes from '../style/Team.module.css';
import InstagramButton from './socials/InstagramButton';
import FacebookButton from './socials/FacebookButton';
import XButton from './socials/XButton';
import TikTokButton from './socials/TikTokButton';
import WebsiteButton from './socials/WebsiteButton';
import CalendarButton from './socials/CalendarButton';

const Member = (props) => {
    return (
        <div className={`${classes.member} ${props.solo&&classes.member_solo}`}>
            <div className={classes.member_photo_area}>
                <img src={require(`../images/people/${props.img}`)} alt='Team Member'/>
            </div>
            <div className={classes.member_info_area}>
                <div className={classes.member_name}>
                    <span className={classes.member_name_first}>{props.firstName}</span>
                    <span className={classes.member_name_last}>{props.lastName}</span>
                </div>
                <div className={classes.member_links}>
                    {props.instagram && <InstagramButton name={props.instagram}/> }
                    {props.x && <XButton name={props.x}/> }
                    {props.facebook && <FacebookButton name={props.facebook}/> }
                    {props.tiktok && <TikTokButton name={props.tiktok}/> }
                    {props.website && <WebsiteButton url={props.website}/> }
                    {props.calendar && <CalendarButton url={props.calendar}/> }
                </div>
                <div className={classes.member_bio}>
                    {props.bio}
                </div>
            </div>
        </div>
    )
}

export default Member;