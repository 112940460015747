import { useRef } from 'react';
import classes from '../style/Contact.module.css';
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = ({form,handleSubmit,loading}) => {

    const captchaRef = useRef();

    return (
        <form ref={form} onSubmit={handleSubmit}>
            <div className={classes.input_row}>
                <div className={classes.input_row_label}>Name</div>
                <input type="text" name="name" />
            </div>
            <div className={classes.input_row}>
                <div className={classes.input_row_label}>Email</div>
                <input type="email" name="email" />
            </div>
            <div className={classes.input_row}>
                <div className={classes.input_row_label}>Phone</div>
                <input type="phone" name="phone" />
            </div>
            <div className={classes.input_row} style={{marginBottom:'20px'}}>
                <div className={classes.input_row_label}>Message</div>
                <textarea name="message" />
            </div>
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                ref={captchaRef}
            />
            <button type="submit">{loading?"Sending...":"Send"}</button>
        </form>
    )
}

export default ContactForm;