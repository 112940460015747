import { NavLink } from 'react-router-dom';
import classes from '../style/Footer.module.css';

const Footer = () => {
    return (
        <div className={classes.footer_area}>
            <div className={classes.links}>
                <NavLink className={classes.footer} to='/'>Home</NavLink>
                <NavLink className={classes.footer} to='/team'>Team</NavLink>
                <NavLink className={classes.footer} to='/gallery'>Gallery</NavLink>
                <NavLink className={classes.footer} to='/contact'>Contact</NavLink>
            </div>
            <div className={classes.business_info}>
                <span className={classes.name}>Back to Basics Movement & Training LLC</span> 
                <span className={classes.address}>205 Intek Way #300, Westerville, OH 43082</span>
                <span className={classes.phone}>(614) 423-9482</span>
            </div>
        </div>
    )
}

export default Footer;